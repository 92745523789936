<template>

	<div>
		<div class="row">
			<div class="col-md-12 d-flex justify-content-center my-3">
				<div class="d-block">
					<div class="d-flex flex-column mt-3" v-for="unitGroup in unitGroups" :key="unitGroup.name">
						<h6 class="d-flex align-items-center" style="width: 100px">
							{{ $t(unitGroup.name) }}
						</h6>
						<div class="d-flex">
							<div>
								<NumberInput :msgs="msgs" :required="true" :decimal="true" :minus="true" v-model.number="unitGroup.value" style="min-width: 120px" class="form-control" />
								<select class=" form-control w-100 " v-model="unitGroup.from"  id="unit">
									<option v-for="unit in unitGroup.units" :disabled="unit.key === unitGroup.to"
										v-bind:key="unit.key" :value="unit.key">{{ unit.key | unitName }}</option>
								</select>
							</div>
							<div class="d-flex align-items-center">
								<button class="btn btn-primary w-100 mx-1 mx-sm-3" v-on:click="switchUnits(unitGroup)">
									<font-awesome-icon icon="exchange-alt" class="m-0" />
								</button>
							</div>
							<div class="">
								<TextInput
									:value="String(getSafeValue(unitGroup.value, unitGroup.from, unitGroup.to))"
									mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" class="w-100 form-control" style="min-width: 120px"
									:readonly="true" />
								<select class=" form-control w-100" v-model="unitGroup.to" id="unit" >
									<option v-for="unit in unitGroup.units" :disabled="unit.key === unitGroup.from"
										v-bind:key="unit.key" :value="unit.key">{{ unit.key | unitName }}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from '@/components/inputs/TextInput.vue';
import NumberInput from "@/components/inputs/NumberInput.vue";

export default {
	name: 'ConverterView',
	components: { TextInput, NumberInput },
	data() {
		return {
			unitGroups: [
				{
					group: 'pressure',
					from: 'inHg',
					to: 'hPa',
					value: 1.00,
					name: 'unitGroup.pressure',
					units: this.$units.pressure,
				},
				{
					group: 'distance',
					from: 'ft',
					to: 'm',
					value: 1.00,
					name: 'unitGroup.distance',
					units: this.$units.distance,
				},
				{
					group: 'mass',
					from: 'lbs',
					to: 'kg',
					value: 1.00,
					name: 'unitGroup.mass',
					units: this.$units.mass,
				},
				{
					group: 'Volume',
					from: 'gal',
					to: 'l',
					value: 1.00,
					name: 'unitGroup.volume',
					units: this.$units.volume,
				},
				{
					group: 'temperature',
					from: 'f',
					to: 'c',
					value: 1.00,
					name: 'unitGroup.temperature',
					units: this.$units.temperature,
				},
				{
					group: 'speed',
					from: 'kt',
					to: 'kmh',
					value: 1.00,
					name: 'unitGroup.speed',
					units: this.$units.speed,
				},
			]
		}
	},
	props: {
		msgs: Object
	},
	methods: {
		switchUnits(unitGroup) {
			let from = unitGroup.from;
			let to = unitGroup.to;
			unitGroup.from = to;
			unitGroup.to = from;
		},

		/**
		 * @param {string|number} value
		 * @param {AllUnitType} from
		 * @param {AllUnitType} to
		 * @returns {number|null}
		 */
		getSafeValue(value, from, to) {
			if (value === null || value === undefined || value === '') {
				return null;
			}
			return this.$units.convert(Number(value), from, to);
		}
	},
}

</script>

<style>

</style>
